.header {
    height: 13rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 2;

    @media (max-width: @screen-s) {
        height: 8rem;
    }
}

.header__inner {
    .grid();
    position: relative;
}

.header__nav {
    display: flex;
    grid-column-start: 3;
    grid-column-end: -1;
    position: relative;

    @media (max-width: @screen-l) {
        grid-column-start: 2;
    }

    @media (max-width: @screen-m) {
        grid-column-start: 1;
    }

    @media (max-width: @screen-s) {
        width: 100%;
        justify-content: space-between;
    }

    a {
        display: block;
        line-height: 1.5;
        font-size: 2rem;
        font-weight: bold;
        text-decoration: none;
        margin: 0 6rem 0 0;
        transition: color 0.3s ease;

        @media (max-width: @screen-s) {
            margin: 0;
            font-size: 1.6rem;
        }

        &._is-active {
            pointer-events: none;
            color: var(--color-light);

            &:after {
                transform: scale(0, 1);
                opacity: 0.7;
            }
        }

        html.desktop &:hover {
            color: var(--color-accent);
        }
    }
}
