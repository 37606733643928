.projects {
    margin-top: 13rem;
    padding-top: 7rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;

    @media (max-width: @screen-s) {
        margin-top: 4rem;
    }
}

.projects-list {
    margin-top: 6rem;
    grid-column-start: 3;
    grid-column-end: 10;
    counter-reset: pro;

    @media (max-width: @screen-m) {
        grid-column-start: 2;
        grid-column-end: 12;
    }

    @media (max-width: @screen-s) {
        // margin-top: 3rem;
        grid-column-end: -1;
    }
}

.project {
    margin-bottom: 9rem;
    counter-increment: pro;

    @media (max-width: @screen-s) {
        margin-bottom: 6rem;
    }

    &:last-child {
        margin-bottom: 0;
    }
}

.project__title {
    font-size: 3rem;
    font-weight: bold;
    position: relative;

    @media (max-width: @screen-s) {
        font-size: 2rem;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: -2.5rem;
        top: 2rem;
        width: 1rem;
        height: 1rem;
        border-radius: 50%;
        background: #ffffff;
        opacity: 0.4;

        @media (max-width: @screen-s) {
            left: -2.8rem;
            top: 1.4rem;
            width: 0.8rem;
            height: 0.8rem;
        }
    }

    a {
        display: inline-block;
        text-decoration: none;

        > span {
            display: inline-block;
            position: relative;
            .underline(0.8rem);

            &:after {
                z-index: 1;

                @media (max-width: @screen-s) {
                    bottom: 5px;
                }
            }

            span {
                display: block;
                position: relative;
                z-index: 2;
            }
        }

        html.desktop &:hover {
            color: var(--color-accent);

            svg {
                opacity: 1;
            }
        }

        svg {
            display: inline-block;
            width: 2.1rem;
            height: 2.1rem;
            margin-left: 1rem;
            color: var(--color-accent);
            transition: inherit;

            @media (max-width: @screen-s) {
                width: 1.6rem;
                height: 1.6rem;
                margin-left: 0.8rem;
            }
        }
    }
}

.project__description {
    margin-top: 1.5rem;
    color: var(--color-light);

    a {
        .underline(0);
        transform: translateZ(0);
        text-decoration: none;
        color: #fff;
        opacity: 0.5;

        html.desktop &:hover {
            color: var(--color-accent);
            opacity: 1;
        }
    }

    p {
        margin: 1.5rem;
    }
}

.project__list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    margin: 1.5rem 0 0 0;
    padding: 0;

    li {
        display: block;
        padding: 0;
        margin-right: 2rem;

        a {
            display: block;
            text-decoration: none;
            .underline(2px);
            transform: translateZ(0);

            html.desktop &:hover {
                color: var(--color-accent);
            }
        }
    }
}
