.contacts {
    margin-top: 13rem;
    padding-top: 7rem;
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;

    @media (max-width: @screen-s) {
        margin-top: 4rem;
    }
}
