// Стандартная рамка при :focus
.focused() {
    // outline: 1px dotted #212121;
    outline: 1px dotted currentColor;
    outline: 5px auto -webkit-focus-ring-color;
}



// Компактный нативный скролл
.scrollbar() {
    // Firefox
    // scrollbar-width: thin;
    // scrollbar-color: #111 var(--color-light);

    // Webkit
    &::-webkit-scrollbar {
        width: 10px;
        height: 10px;
        background: #111;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background-color: var(--color-light);
    }
}



// Невидимый, но доступный с клавиатуры и скринридерам
.v-hidden() {
	position: absolute;
	overflow: hidden;
	margin: -1px;
	width: 1px;
    height: 1px;
    top: 0;
    left: 0;
	clip: rect(0 0 0 0);
}


.underline(@bottom: 0) {
    position: relative;
    white-space: nowrap;

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: @bottom;
        height: 0.2rem;
        // background: var(--color-light);
        background: var(--color-accent);
        left: 0;
        width: 100%;
        will-change: transform;
        pointer-events: none;
        transition: all 0.2s ease;
        // opacity: 0.3;
    }

    html.desktop &:hover {

        &:after {
            transform: scale(0, 1);
            opacity: 0.7;
        }
    }
}


.local(@bottom: 0) {
    position: relative;
    white-space: nowrap;
    transform: translateZ(0);

    &:after {
        content: '';
        display: block;
        position: absolute;
        bottom: @bottom;
        height: 0.2rem;
        background: var(--color-accent);
        left: 0;
        width: 100%;
        will-change: transform;
        pointer-events: none;
        transition: all 0.2s ease;
        transform: scale(0, 1);
        opacity: 0.7;
    }

    html.desktop &:hover {

        &:after {
            transform: scale(1, 1);
            opacity: 1;
        }
    }
}

.grid() {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 0;
}
