.progressbar {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 0;
    width: 7rem;
    margin: -2.5rem 0 0 -3.5rem;
    transition: all 0.2s ease;

    html._is-loaded & {
        opacity: 0;
        visibility: hidden;
    }

    div {
        position: absolute;
        top: 0;
        width: 0.6rem;
        height: 0.6rem;
        background: rgba(0, 0, 0, 0.25);
        border-radius: 50%;
        margin-left: -0.3rem;
        animation: progress 1.2s infinite ease-in-out;

        &:nth-child(1) {
            left: 0;
            animation-delay: 0.2s;
        }

        &:nth-child(2) {
            left: 33.33%;
            animation-delay: 0.3s;
        }

        &:nth-child(3) {
            left: 66.66%;
            animation-delay: 0.4s;
        }

        &:nth-child(4) {
            left: 100%;
            animation-delay: 0.5s;
        }
    }
}


@keyframes progress {
    0% {
        background: rgba(255, 255, 255, 0.25);
    }
    50% {
        background: var(--color-accent);
    }
    100% {
        background: rgba(255, 255, 255, 0.25);
    }
}
