.wrap {
    width: 94rem;
    margin-left: auto;
    margin-right: auto;

    @media (max-width: @screen-m) {
        width: 84vw;
    }
}

.wrap-wide {
    margin-left: 6rem;
    margin-right: 6rem;
}

.content {
    padding-bottom: 15rem;

    @media (max-width: @screen-s) {
        padding-bottom: 10rem;
    }
}
