.hello {
    position: relative;
    z-index: 1;
    width: 100vw;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    overflow: hidden;

    canvas {
        display: block;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        opacity: 0;
        transition: opacity 1s ease-out;
    }

    html._is-loaded & {

        canvas {
            opacity: 1;
        }
    }
}

.hello__bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 2;
    text-align: center;
}

.hello__bottom-inner {
    .wrap();
    .grid();
}

.hello__next {
    display: block;
    width: 100%;
    height: 7rem;
    color: #fff;
    position: relative;
    opacity: 0;
    pointer-events: none;
    transition: color 0.3s ease,
                opacity 1s ease-out 0.5s;

    @media (max-width: @screen-s) {
        height: 5rem;
    }

    html.desktop &:hover {
        color: var(--color-accent);

        .hello__next-triangle {
            transform: scale(1.25);
        }
    }

    html._is-loaded & {
        opacity: 1;
        pointer-events: auto;
    }

    > span {
        .v-hidden();
    }
}

.hello__next-inner {
    position: absolute;
    left: 50%;
    margin-left: -31.5rem; // -31.5, -39.5

    @media (max-width: @screen-l) {
        margin-left: -39rem;
    }

    @media (max-width: @screen-m) {
        margin-left: -42vw;
    }

    @media (max-width: @screen-s) {
        transform-origin: left;
        transform: scale(0.75);
    }
}

.hello__next-triangle {
    position: relative;
    transition: transform 0.2s ease;
    pointer-events: none;
    grid-column-start: 3;
    grid-column-end: 5;
    // margin-left: -2.7rem;

    @media (max-width: @screen-s) {
        // transform: scale(0.5);
    }

    svg {
        display: block;
        width: 10rem;
        height: 2.1rem;
        position: relative;
        animation: swing 2s ease-in-out infinite;
        pointer-events: none;
    }
}

@keyframes swing {
    0% {
        transform: translateY(0%)
    }
    50% {
        transform: translateY(50%)
    }
    100% {
        transform: translateY(0%)
    }
}
