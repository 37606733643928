// Цвета
:root {
    --color-black: #111;
    // --color-black: #191919;
    --color-light: rgba(255,255,255,0.5);
    --font-size: 1.6rem;
    --color-accent: #01e8cc;
    --color-accent: #01e27e;
    --color-accent: #01e27e;

    @media (max-width: @screen-s) {
        --font-size: 1.5rem;
    }
}

// Breakpoints
@screen-xs: 359px;
@screen-s: 767px;
@screen-m: 999px;
@screen-l: 1299px;
@screen-xl: 2000px;
