.dg.ac {
    z-index: 9 !important;
}

html {
    background: var(--color-black);
    color: #fff;
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 300;
    overflow-x: hidden;

    @media (max-width: 359px) {
        font-size: 9px;
    }

    @media (min-width: 1921px) {
        font-size: 0.5vw;
    }
}

html:not(.mobile) {
    .scrollbar();
}

body {
    font-size: var(--font-size);
    line-height: 1.75;
    // width: 100vw;
    overflow-x: hidden;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.5s ease;

    html._is-preloaded & {
        opacity: 1;
        pointer-events: auto;
    }
}

a {
    color: currentColor;
    // color: var(--color-accent);
    text-decoration-color: var(--color-light);
    transition: all 0.2s ease;
}

::selection {
    color: #000;
    background: var(--color-accent);
}

.h1 {
    font-size: 9rem;
    margin: 0 0 4rem 0;
    font-weight: bold;
    line-height: 1.2;
    // text-shadow: 0.04em 0.04em 0px #40ab8d;
    // color: var(--color-accent);
    grid-column-start: 2;
    grid-column-end: -1;
    position: relative;

    @media (max-width: @screen-m) {
        grid-column-start: 1;
    }

    @media (max-width: @screen-s) {
        font-size: 6rem;
        margin-bottom: 3rem;
    }
}

.h2 {
    font-size: 5rem;
    margin: 8rem 0 2rem -7.6rem;
    // margin: 8rem 0 2rem 0;
    font-weight: bold;
    // color: var(--color-accent);
    // text-shadow: 0.05em 0.05em 0px #40ab8d;

    @media (max-width: @screen-m) {
        margin-left: -5rem;
    }

    @media (max-width: @screen-s) {
        font-size: 3.4rem;
        margin-top: 6rem;
        margin-left: 0;
    }
}

p {
    margin: 1.5em 0;
}


.text {
    grid-column-start: 3;
    grid-column-end: 10;
    position: relative;

    > *:first-child {
        margin-top: 0 !important;
    }

    > *:last-child {
        margin-bottom: 0 !important;
    }

    @media (max-width: @screen-m) {
        grid-column-start: 2;
        grid-column-end: 12;
    }

    @media (max-width: @screen-s) {
        grid-column-start: 1;
        grid-column-end: -1;
    }

    a {
        text-decoration: none;
        .underline();

        html.desktop &:hover {
            color: var(--color-accent);
        }
    }

    p.big {
        font-size: 3rem;
        font-weight: bold;
        margin-top: 0;
        // color: var(--color-light);

        @media (max-width: @screen-s) {
            font-size: 2.4rem;
        }
    }

    p.accent {
        color: var(--color-accent);
        // background: linear-gradient(130deg, #8cf3c1, var(--color-accent));
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
    }
}

b {
    font-weight: bold;
}
