.about {
    .grid();
    padding-top: 7rem;
    margin-top: 3rem;
    position: relative;

    @media (max-width: @screen-s) {
        margin-top: 0;
    }
}

.about__canvas {
    position: absolute;
    right: -10rem;
    top: 24rem;
    width: 38rem;
    height: 38rem;
    display: none;

    @media (max-width: @screen-l) {
        right: 0;
    }

    @media (max-width: @screen-m) {
        display: none;
    }
}
